import React from "react";
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { CookieSetting } from "cookiesetting-component";
import { MortgageGuideRoutes } from "GenericMortgageGuides";
import Footer from "./components/FooterNew/index";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const location = window.location.pathname;
const baseName = process.env.REACT_APP_BASENAME;

let basePath = baseName;
if (baseName === "/streamline-va") {
  basePath = basePath + "/";
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
        {location === baseName || location === "/streamline-va/" ? <App /> : ""}
    {location !== baseName && location !== "/streamline-va/" ?
      <MortgageGuideRoutes  homePath={basePath} showHeader={true} showFooter={true} FooterComponent={Footer} /> : ""}
        <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </BrowserRouter>
  </React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
